function initSignalR(socket, token) {
  if (socket) {
    if (token) {
      socket.authenticate(token, {
        log: false // Logging is optional but very helpful during development
      });
    } else if (socket.stop) {
      try {
        socket.stop();
      } catch {}
    }
  }
}

export default {
  setUser(state, payload) {
    state.token = payload.token;
    state.refreshToken = payload.refreshToken;
    state.userId = payload.userId;
    state.firstName = payload.firstName;
    state.lastName = payload.lastName;
    state.email = payload.email;
    state.avatar = payload.avatar;
    state.avatarUrl = payload.avatarUrl;
    state.permissions = payload.permissions;
    state.roleLevel = payload.roleLevel;

    if (payload.orgInfo) {
      state.orgInfo = {
        orgId: payload.orgInfo.orgId,
        orgName: payload.orgInfo.orgName,
        defaultCountryCode: payload.orgInfo.defaultCountryCode
      };
    }

    // start signalR listener if logged in
    initSignalR(this._vm.$socket, state.token);
  },
  setTokens(state, tokens) {
    state.token = tokens.token;
    state.refreshToken = tokens.refreshToken;
    initSignalR(this._vm.$socket, state.token);
  },
  setAvatar(state, newImage) {
    state.avatar = newImage.fullFileName;
    state.avatarUrl = newImage.url;
  }
};
