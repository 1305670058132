import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      /* User */
      userId: null,
      firstName: null,
      lastName: null,
      email: null,
      avatar: null,
      avatarUrl: null,
      token: null,
      refreshToken: null,
      orgInfo: {},
      permissions: [],
      roleLevel: null
    };
  },
  mutations,
  actions,
  getters
};
